import { memo } from "react";
import defaultPath from "utiles/path";
import { Link } from "react-router-dom";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import Styles from "stylesheets/index/OffersList.module.scss";
import { Offer } from "models";
import { linkFromType } from "types/linkFromType";
import { OfferListItem } from "components/common/OfferListItem";
import { offerType, offerwallType } from "types/offerwallType";
import useLandscape from "hooks/useLandscape";
import TutorialOfferWrapper from "SkyflagFrontendOfferwall/components/index/TutorialOfferWrapper";

interface Color {
  appAreaTxtColor: string;
  appAreaBgColor: string;
  mcvTxtColor: string;
  cvConditionColor: string;
  clockTxtColor: string;
}

interface Texts {
  preRegistText: string;
  stepUpRewardText: string;
}
interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

interface Props {
  color: Color;
  texts: Texts;
  superSale: SuperSale;
  pointIconUrl: string;
  recommendIconUrl: string;
  videoIconUrl?: string;
  offers: Offer[];
  previousPath?: string;
  linkFrom?: linkFromType;
  BannerElement?: JSX.Element;
  pointUnit?: string;
  offerwallType?: offerwallType;
}

const OffersList = ({
  color,
  texts,
  superSale,
  pointIconUrl,
  recommendIconUrl,
  videoIconUrl,
  offers,
  previousPath = "",
  linkFrom,
  BannerElement,
  pointUnit,
  offerwallType,
}: Props) => {
  const { location } = useSelector(selectRouter);
  const queries = new URLSearchParams(window.location.search);
  const insertPosition = {
    Banner: 0,
  };

  const isLandscape = useLandscape();

  const rowComponents = offers
    ?.filter((data) => data.id !== 0)
    .map((offerData: Offer, i: number) => (
      // モックデータのページネーションでkey一致のエラーが出るため暫定処理としてkeyを変更しております
      <TutorialOfferWrapper
        isTutorial={offerData.isTutorialOffer && isLandscape}
        tutorialOfferActionUrl={offerData.tutorialOfferActionUrl}
        pointProps={{
          pointUnit,
        }}
        viewType="top"
        key={i}
      >
        <Link
          to={{
            pathname: `${defaultPath.INDEX}/detail`,
            search: new URLSearchParams({
              ...Object.fromEntries(queries.entries()),
              offer: String(offerData.id),
              position: String(i + 1),
              linkfrom: String(linkFrom),
            }).toString(),
            state: { display: location?.state?.display, previousPath, referrer: location },
          }}
        >
          <OfferListItem
            position={i + 1}
            timeSale={{
              startAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.startAt,
              endAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.endAt,
            }}
            superSale={superSale}
            color={color}
            text={offerData.isSur ? texts.stepUpRewardText : ""}
            actualPoint={offerData.actualPoint}
            basePoint={offerData.basePoint}
            pointIconUrl={pointIconUrl}
            pointUnit={pointUnit}
            recommendIconUrl={offerData.isRecommended && recommendIconUrl}
            isVideo={offerData.isVideo}
            videoIconUrl={videoIconUrl}
            isSur={offerData.isSur}
            iconUrl={offerData.iconUrl}
            title={offerData.displayName}
            cvCondition={offerData.cvCondition}
            isClock={!!offerData.conversionPoints.slice(-1)[0]?.timeSale?.point}
            isPercentage={offerData.isPercentage}
            isPickUp={offerwallType === offerType.PICKUP}
            isLast={offers.length === i + 1}
          />
        </Link>
      </TutorialOfferWrapper>
    ));
  if (offerwallType === offerType.PICCOMA_APP || offerwallType === offerType.PICCOMA_WEB) {
    rowComponents && rowComponents.splice(insertPosition.Banner, 0, BannerElement);
  }

  return (
    <div className={Styles.offerList} id="offerList">
      {rowComponents}
    </div>
  );
};

export default memo(OffersList);
