import { Expose, Type } from "class-transformer";
import { ScheduledReceivePoints } from "models";

export class Waiting {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline: string;

  @Type(() => ScheduledReceivePoints)
  scheduledReceivePoints?: ScheduledReceivePoints[];

  @Expose({ name: "cvCondition" })
  cvCondition: string;

  @Expose({ name: "cvEstimatedTime" })
  cvEstimatedTime: string;

  @Expose({ name: "displayAppName" })
  displayAppName: string;

  @Expose({ name: "iconUrl" })
  iconUrl: string;

  @Expose({ name: "listImageUrl" })
  listImageUrl: string;

  @Expose({ name: "registeredAt" })
  registeredAt: string;

  @Expose({ name: "approvedDate" })
  approvedDate: string;

  @Expose({ name: "challengedDate" })
  challengedDate: string;

  @Expose({ name: "userOfferId" })
  userOfferId: number;
}
