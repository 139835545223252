import { useEffect, useState } from "react";
import { offerApi } from "apis/offer";
import { Survey } from "models";
import { plainToClass } from "class-transformer";
import { selectMediaState } from "store/selector";
import { useSelector } from "react-redux";
import { removeEmpty } from "modules/removeEmpty";

export const useSurvey = (): [boolean, boolean, Survey[] | undefined] => {
  const media = useSelector(selectMediaState);
  const queries = new URLSearchParams(window.location.search);
  const suid = queries.get("suid") ?? "";
  const [surveyData, setSurveyData] = useState<Survey[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // muidがないときは空データを返す
  if (!suid) return [null, true, []];

  useEffect(() => {
    offerApi
      .getSurvey<Survey>(
        removeEmpty(
          new URLSearchParams({
            mediaUserId: suid,
            mediaId: String(media.id),
            linkfrom: "ow",
            ...Object.fromEntries(queries),
          })
        )
      )
      .then((result) => {
        setSurveyData(result.map((data) => plainToClass(Survey, data)));
        setIsLoaded(true);
      })
      .catch(() => {
        // アンケートAPI呼び出し時にエラーになったらOWを落としてしまうので握りつぶす
        setIsError(false);
        setIsLoaded(true);
      });

    return () => {
      setSurveyData([]);
      setIsError(false);
      setIsLoaded(false);
    };
  }, [media.id]);

  return [isError, isLoaded, surveyData];
};
