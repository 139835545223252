import baseAxios from "./axios";

class OfferApi {
  async getOffer<T>(owp: string, offerId: string | number, params: URLSearchParams): Promise<T> {
    const res = await baseAxios.get<T>(
      `${process?.env?.REACT_APP_OFFERWALL_API}/detail/${owp}/${offerId}`,
      {
        params,
      }
    );
    return res.data;
  }

  async getOffers<T>(
    owp: string,
    mediaUserId: string,
    spram1: string,
    spram2: string,
    display_offer_type: number,
    sad: string,
    serv: string,
    close_link: string
  ): Promise<T> {
    const res = await baseAxios.get<T>(`${process?.env?.REACT_APP_OFFERWALL_API}/top/${owp}`, {
      params: {
        mediaUserId,
        spram1,
        spram2,
        display_offer_type,
        sad,
        serv,
        close_link,
      },
    });
    return res.data;
  }

  async getChallenges<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_API}/offer/challenging`, {
      params,
    });
    return res.data;
  }

  async getChallenge<T>(owp: string, offerId: string | number, params: URLSearchParams) {
    const res = await baseAxios.get<T>(
      `${process?.env?.REACT_APP_OFFERWALL_API}/challenge/detail/${owp}/${offerId}`,
      {
        params,
      }
    );
    return res.data;
  }

  async getPreRegist<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_API}/offer/preRegist`, {
      params,
    });
    return res.data;
  }

  async getHistory<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_API}/offer/history`, {
      params,
    });
    return res.data;
  }

  async getHistoryDetail<T>(owp: string, offerId: string | number, params: URLSearchParams) {
    const res = await baseAxios.get<T>(
      `${process?.env?.REACT_APP_OFFERWALL_API}/history/detail/${owp}/${offerId}`,
      {
        params,
      }
    );
    return res.data;
  }

  async getWaiting<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_API}/offer/waiting`, {
      params,
    });
    return res.data;
  }

  async getWaitingDetail<T>(owp: string, offerId: string | number, params: URLSearchParams) {
    const res = await baseAxios.get<T>(
      `${process?.env?.REACT_APP_OFFERWALL_API}/waiting/detail/${owp}/${offerId}`,
      {
        params,
      }
    );
    return res.data;
  }

  async getSurvey<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_API}/survey/survey`, {
      params,
    });
    return res.data;
  }

  async getVideoEventConversionUrl<T>(url: string): Promise<T> {
    const res = await baseAxios.get<T>(url);
    return res.data;
  }

  async getVideoEventConversionStatus<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_OFFERWALL_API}/video/cv`, {
      params,
    });
    return res.data;
  }

  async getTutorialOfferEventConversionUrl<T>(url: string): Promise<T> {
    const res = await baseAxios.get<T>(url);
    return res.data;
  }

  async getTutorialOfferConversionStatus<T>(params: URLSearchParams): Promise<T[]> {
    const res = await baseAxios.get<T[]>(`${process?.env?.REACT_APP_OFFERWALL_API}/tutorial/cv`, {
      params,
    });
    return res.data;
  }
}

export const offerApi = new OfferApi();
